import { navigate } from "gatsby"
import React, { useState } from "react"
import { Layout } from "../components"
import { Button } from "../components/common/button"
import * as config from "../config"
import { useAuth } from "../middleware/AuthGuard"
import * as styles from "./signin.module.scss"

const Signin: React.FC = () => {
  const [account, setAccount] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<string | null>(null)
  const { login } = useAuth()

  const handleSignin = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      await login(account, password)
    } catch (err) {
      setError("ログインに失敗しました")
    }

    return false
  }

  return (
    <Layout>
      <div className={styles.signinContainer}>
        <h2>ログイン</h2>
        <form onSubmit={handleSignin}>
          <div className={styles.formGroup}>
            <label htmlFor="account">アカウント</label>
            <input
              autoCapitalize="off"
              type="text"
              id="account"
              name="account"
              value={account}
              onChange={e => setAccount(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">ログインパスワード</label>
            <input
              autoCapitalize="off"
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <Button
            layout="fill"
            type="submit"
            disabled={account == "" || password == ""}
          >
            ログイン
          </Button>
        </form>
        <div className={styles.links}>
          <p>
            ログインパスワードがわからない場合は
            <a href={`${config.QTNET_BASE_URL}/forgot-password`}>こちら</a>
          </p>
          <p className={styles.notice}>
            ※ログインパスワードを3回間違えると、当日は利用できなくなりますのでご注意ください。
          </p>
          <p>
            アカウント名がわからない場合は
            <a href={`${config.QTNET_BASE_URL}/forgot-login-account`}>こちら</a>
          </p>
        </div>
        <Button
          variant="secondary"
          layout="fill"
          onClick={() => navigate(`${config.QTNET_BASE_URL}/signup`)}
        >
          新規会員登録はこちら
        </Button>
      </div>
    </Layout>
  )
}

export default Signin
